.mhd {
  &--page-footer {
    left: 0;
    right: 0;
    bottom: 0;
    height: 52px;
    z-index: 2003;
    position: fixed;
    padding: 8px 15px;
    background: #ecf0f1;

    .btn {
      margin: 0 3px;
    }

    div {
      .ui.button:not(:last-child) {
        margin-right: 14px;
      }

      &.pull-left {
        float: left !important;
      }

      &.pull-right {
        float: right !important;
      }
    }
  }
}
