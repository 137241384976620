.mhd {
  &--modal--close {
    .close {
      opacity: 1;
      margin-top: 2px;
    }
  }

  &--divider-wrapper {
    padding: 0 45px;
  }
}
