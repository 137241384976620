.mhd--pagination {
  padding-top: 0.5rem;
  display: inline-block;

  .ui.buttons .button.basic {
    border: 1px solid rgba(34, 36, 38, 0.15);
  }

  .ui.buttons .button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: solid 0.5px inherit;
  }

  .ui.buttons .button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: solid 0.5px inherit;
  }

  button,
  [type='button'] {
    -webkit-appearance: none !important;
  }
}
