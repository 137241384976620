.mhd {
  &--full-page {
    margin-top: -22px;
    margin-left: -15px;
    margin-right: -15px;

    &-content {
      margin: 0 30px 30px 30px;
    }

    .fixed-width {
      max-width: 960px;
    }

    .fluid {
      max-width: 100%;
    }

    .mhd-header {
      padding: 20px 20px 0 20px;
      display: flex;

      h1 {
        flex: none;
        margin-right: 15px;
      }

      hr {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}
