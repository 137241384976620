.mhd {
  &--grid {
    &--card {
      margin: 10px 0;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #f3f2f2;
      min-height: 42px;

      &:hover {
        background-color: #f5f6f6;
      }

      & .mhd--title {
        color: #bbb;
        display: block;
        font-size: 10px;
        text-transform: uppercase;
      }

      &--clickable {
        cursor: pointer;
        color: rgb(17, 130, 175);

        &:hover {
          text-decoration: underline;
        }
      }

      &.empty-card {
        color: #9a9a9a;
      }

      .ui.grid > .row.grouped {
        background: white;
        border-radius: 8px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        margin-bottom: 6px !important;
        padding-left: 20px !important;
      }
      .ui.grid > .row.grouped:last-of-type {
        margin-bottom: 10px !important;
      }

      & .bold {
        font-weight: bold;
      }

      .ui.checkbox:not(.toggle) input:checked ~ .box:after,
      .ui.checkbox:not(.toggle) input:checked ~ label:after {
        color: #9a9a9a;
      }

      .error {
        color: #cc3d2c;
      }

      .ui.grid > .column:not(.row),
      .ui.grid > .row > .column {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }

      .ui.grid > .row > .column:last-of-type {
        padding-right: 0.2rem;
      }
    }
  }
}
