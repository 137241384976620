.mhd--page {
  &--header {
    padding: 20px 30px;

    h1 {
      display: inline-block;
    }

    &-actions {
      float: right;
    }
  }
}
