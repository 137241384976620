.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 38px;
}
