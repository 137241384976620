.number-picker--override {
  button,
  .field {
    min-width: 0 !important;
  }

  input {
    width: 75px !important;
  }
}
