.mhd {
  &--collapsible-panel {
    padding: 15px 30px 20px 30px !important;

    h3 {
      text-transform: uppercase !important;
      margin-top: 0 !important;
    }

    .legend-items {
      margin: -12px -12px 0 -12px;
    }

    .legend-item {
      display: inline-block;
      margin: 19px 12px 0 12px !important;
      line-height: 26px;

      img {
        margin-top: 0 !important;
        margin-right: 8px !important;
        width: 26px !important;
      }
    }

    .toggle {
      top: 10px;
      right: 20px;
      float: right;
    }

    .ui.divider {
      margin: 12px 0;
    }
  }
}
