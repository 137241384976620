.mhd {
  &--quick-view {
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1001;
    color: #333;
    text-align: left;
    position: absolute;
    background-color: #fff;

    & .header {
      padding: 15px 0;
      font-size: 11px;
      text-transform: uppercase;
      color: rgb(172, 172, 172);
    }

    &--close {
      top: 30px;
      left: 30px;
      z-index: 10;
      cursor: pointer;
      font-size: 15px;
      position: absolute;
    }

    &--content {
      top: 0;
      left: 0;
      right: 0;
      bottom: 90px;
      overflow-y: auto;
      position: absolute;
      padding: 50px 30px 50px 30px;
    }

    &--actions {
      right: 0;
      bottom: 0;
      width: 100%;
      position: fixed;
      background-color: #fff;
      padding: 5px 30px 30px 30px;
    }
  }

  &--animation {
    &--animation-in {
      -webkit-animation: slide-in-right 0.5s
        cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
      animation: slide-in-right 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
    }

    &--fade-in {
      -webkit-animation: fade-in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
        both;
      animation: fade-in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
    }
  }
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
