.mhd {
  &--stat-filter {
    display: inline-block;
    padding: 6px;
    margin: 0 20px 15px 0;
    text-align: center;
    color: #9a9a9a;

    &.active {
      border-bottom: 2px solid #04508d;

      h3.ui.header {
        font-weight: bold;
        color: #04508d;
      }
    }

    .header {
      display: inline-block;
    }
  }
}
