.mhd {
  &--grid--headers {
    .mhd--table-header-dropdown i.dropdown.icon {
      display: none;
    }
  }
}

.mhd--header {
  font-weight: bold;

  &:not(.mhd--no-click) {
    cursor: pointer;
  }
}
