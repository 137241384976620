.mhd {
  &--grid {
    &--headers {
      padding: 0 20px;

      .ui.checkbox input:checked ~ .box:after,
      .ui.checkbox input:checked ~ label:after {
        color: #9a9a9a;
      }

      .ui.grid > .column:not(.row),
      .ui.grid > .row > .column {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }
    }
  }
}
