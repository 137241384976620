.mhd--color {
  &--circle {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
  }

  &-swatch {
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 1px;
    background: #eee;
    display: inline-block;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

    &--color {
      width: 36px;
      height: 14px;
      border-radius: 2px;

      &--text {
        font-size: 11px;
        margin-bottom: 10px;
      }
    }

    &--popover {
      z-index: 2;
      position: absolute;
    }

    &--cover {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
    }
  }
}
