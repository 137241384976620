.mhd {
  &--rounded-container {
    margin-top: 30px;
    position: relative;
    border-radius: 16px;
    padding: 30px 20px 20px;
    border: 2px solid lighten(#717171, 40%);

    .title {
      padding: 0 5px;
      font-size: 26px;
      color: #717171;
      margin-top: -40px;
      background: #fff;
      position: absolute;
    }
  }
}
