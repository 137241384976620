.error-full-page {
  height: 100%;

  img.error-background {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
  }

  img.spaceman {
    width: auto;
    position: fixed;
    right: 0;
    bottom: 50px;
  }

  .error-text {
    text-align: center;
    color: #ffffff;
    opacity: 0.88;
  }

  .error-header {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
  }

  .error-blurb {
    font-size: 24px;
    line-height: 34px;
    margin-top: 11px;
  }

  @media (max-width: 1251px) {
    img.error-background {
      width: inherit;
    }
    img.spaceman {
      width: 50%;
    }
  }
}
