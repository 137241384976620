.mhd {
  &--loading {
    z-index: 10000;

    &.inline-load {
      padding: 0;
      text-align: center;

      > img {
        position: inherit;
        margin: 0;
        left: unset;
        top: unset;
      }
    }

    > img {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-left: -48px;
      margin-top: -48px;
    }

    &.centered {
      top: 50%;
      left: 50%;
      margin-top: -48px;
      margin-left: -48px;
      position: fixed !important;
    }
  }
}
